export default {
    init() {
        
        $('[data-menu-mobile] [data-toggle]')
            .on('click', e => {
                e.preventDefault();
                e.stopPropagation();
                
                $(e.currentTarget)
                    .parents('[data-menu-mobile]')
                    .toggleClass('open');
            });

    }
}



