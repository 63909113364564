import { equalLines } from 'jquery-equal-lines';

export default {
    init() {
        
        //$('[data-equal-lines]').equalLines();

    }
}






