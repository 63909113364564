import { Drawer, FocusTrap } from 'pickles';

export default {
    init() {
        
        const focusTrap = new FocusTrap();
        const drawer = new Drawer();

        $(document)
            .keyup(e => {
                if ( e.keyCode === 27 ) {
                    drawer.close(); // escape to close
                }
            });

        $(document)
            .on('drawerOpen', e => {
                $('[data-drawer-toggle]').attr('aria-expanded', true);
                focusTrap.start('#drawer');
            })
            .on('drawerClose', e => {
                $('[data-drawer-toggle]').attr('aria-expanded', false);
                focusTrap.stop();
            });

    }
}