export default {
    init() {
      console.log('content');
  
      $('.article img.alignleft').wrap('<span class="inline-image image--alignleft"></span>');
      $('.article img.alignright').wrap('<span class="inline-image image--alignright"></span>');
    },
    finalize() {
      
    },
  };
  