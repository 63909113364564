import { Modal } from 'pickles';

export default {
    init() {
        
        // Modal
        const modal = new Modal();

        $('[data-modal]')
            .on('keypress', e => {
                if ( e.type === 'keypress' && e.keyCode === 13 ) {
                    $(e.currentTarget).click();
                }
            });

        if ( location.hash === '#register' ) {
            $('a[href="#portal-auth-modal"]').click();
        }

    }
}

        
