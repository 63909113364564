export default {
    init() {
        
        $('.tabs .tab')
            .on('click keypress', e => {
                if ( e.type === 'keypress' && e.keyCode !== 13) {
                    return;
                }
                
                const tab = $(e.currentTarget);
                tab.addClass('active');
                tab.siblings('.tab').removeClass('active');
                
                const target = $(tab.data('target'));
                target.addClass('active');
                target.siblings('.tabs-content').removeClass('active');
            });

    }
}






