import { Appear, ScrollEffects } from 'pickles';

import Accordions from '../libs/accordions';
import Lightboxes from '../libs/lightboxes';
import Drawer from '../libs/drawer';
import Modals from '../libs/modals';
import Menus from '../libs/menus';
import Tabs from '../libs/tabs';
import Carousels from '../libs/carousels';
import Typography from '../libs/typography';
import Forms from '../libs/forms';
import Content from '../libs/content';

export default {
    init() {

        console.log('common [parent]');
        window.$ = window.$ || jQuery;

        // lightboxes
        Lightboxes.init(); 

        // drawer
        Drawer.init();

        // modals
        Modals.init();

        // scroll effects
        const scrollEffects = new ScrollEffects();

        // appear
        const appear = new Appear();

        // accordions
        Accordions.init();

        // menus
        Menus.init();

        // tabs
        Tabs.init();

        // carousel
        Carousels.init();

        // typography
        Typography.init();

        // forms
        Forms.init();

        // content
        Content.init();

        // ensure scrolling effects are initialized properly

        $(window).on('load', e => {
            $(window).trigger('scroll');
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
