import { Lightbox, FocusTrap } from 'pickles';

export default {
    init() {
        
        const focusTrap = new FocusTrap();
        const lightbox = new Lightbox({ closeButton: true });

        $(document)
            .on('lightboxOpen', e => {
                focusTrap.start(e.detail.el);
            })
            .on('lightboxClose', e => {
                focusTrap.stop();
            }); 

    }
}