import { tns } from 'tiny-slider/src/tiny-slider';

export default {
    init() {
        
        $('[data-slider]')
            .each((index, el) => {

                // default breakpoints

                let itemBreakpoints = {
                    xs: 1,
                    sm: 2, 
                    md: 3,
                };

                // element attr is json object of breakpoints

                if ( typeof $(el).data('slider-items') === 'object' ) {
                    itemBreakpoints = $(el).data('slider-items');
                }

                // element attr is number - use for all breakpoints

                if ( typeof $(el).data('slider-items') === 'number' ) {
                    for ( let breakpoint in itemBreakpoints ) {
                        itemBreakpoints[breakpoint] = $(el).data('slider-items');
                    }
                }                

                let slideroptions = {
                    container: el,
                    items: itemBreakpoints.xs || 1,
                    responsive: {
                        480: { // 30rem = xs
                            items: itemBreakpoints.sm || 1,
                        },
                        768: { // 48rem = sm
                            items: itemBreakpoints.md || 1
                        },                        
                    },                   
                    slideBy: 1,
                    speed: 800,
                    fixedWidth: false,
                    autoplay: false,
                    autoplayHoverPause: true,
                    gutter: $(el).data('slider-gutter') || 16,
                    autoplayButtonOutput: false,
                    nav: $(el).data('slider-nav') || false,
                    controls: $(el).data('slider-controls') || false,
                }        
            
                if ( slideroptions.nav ) {
                    // slideroptions.navContainer = $(el).parent().find('.tns-nav')[0];
                    slideroptions.navPosition = 'bottom';
                }
            
                if ( slideroptions.controls ) {
                    slideroptions.prevButton = $(el).parent().find('.tiny-slider-previous')[0];
                    slideroptions.nextButton = $(el).parent().find('.tiny-slider-next')[0];
                }

                const slider = tns(slideroptions);
            });
    }
}








