import { AjaxForms } from 'pickles';

export default {
    init() {
        
        const ajaxForms = new AjaxForms({
            ajaxUrl: env.ajaxUrl
        });

    }
}

        
