export default {
    init() {
        
        // Accordion
        $('.accordion--title')
            .on('click keypress', (e) => {
                if ( e.type === "keypress" && e.keyCode !== 13 ) {
                    return;
                }
                $(e.currentTarget).parent('.accordion').toggleClass('accordion--open');
                $('.schedule-row').removeClass('accordion--open');
                $(e.currentTarget).parents('.schedule-row').toggleClass('accordion--open');
            })
            .first()
            .trigger('click');

    }
}